<template>
  <div>
    <div class="col-xl-12 px-0">
      <div
        class="card card-custom card-stretch gutter-b"
        :class="error.active ? 'bordered-card-danger' : 'bordered-card-primary'"
      >
        <div class="card-body">
          <div class="d-flex justify-content-start">
            <div>
              <h6>{{ $t('SETUP.NAV.BASIC_PROFILING') }}</h6>
              <p class="mb-10">{{ $t('SETUP.BASIC_PROFILING.DESCRIPTION') }}</p>              
            </div>
          </div>

          <b-alert v-if="error.active" show variant="danger">
            {{ error.message }}
          </b-alert>

          <b-spinner
            v-if="loadingComplete == false && error.active == false"
            variant="primary"
            label="Spinning"
            class="card-loader"
          >
          </b-spinner>

          <div v-if="loadingComplete" class="table-responsive">
            <div class="col-xl-4 table-responsive">
              <table
                id="basic_profiling_table"
                class="table table-head-custom table-vertical center table-hover mt-5 w-100"
                style="table-layout:auto"
              >
                <thead>
                  <tr>
                    <th class="px-2 py-3" style="width:50px"></th>
                    <th class="px-2 py-3">
                      {{ $t('TABLES.BASIC_PROFILE_HEADERS.NAME') }}
                    </th>
                    <th
                      class="px-2 py-3 text-center"
                      style="width:100px"
                    >
                      {{ $t('TABLES.BASIC_PROFILE_HEADERS.REQUIRED') }}
                    </th>
                    <th
                      class="px-2 py-3 text-center"
                      style="width:160px"
                    >
                      {{ $t('TABLES.BASIC_PROFILE_HEADERS.DISPLAY') }}
                    </th>                    
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(question, i) in profile_questions">
                    <BasicProfileQuestionRow
                      :key="i"
                      :data="question"
                      :index="i"
                      @activate_question="activate_question"
                      @deactivate_question="deactivate_question"
                      @update_question="update_question"
                    >
                    </BasicProfileQuestionRow>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import { mapGetters } from "vuex";
import { POPULATE_PROFILE_QUESTIONS } from "@/core/services/store/system/system.module";
import {
  POPULATE_BASIC_PROFILE_QUESTIONS,
  CREATE_BASIC_PROFILE_QUESTION,
  DELETE_BASIC_PROFILE_QUESTION,
  UPDATE_BASIC_PROFILE_QUESTION
} from "@/core/services/store/project/project_basic_profile_questions.module";

import BasicProfileQuestionRow from "@/view/content/projects/BasicProfileQuestionRow.vue";

export default {
  name: "BasicProfiling",

  components: {
    BasicProfileQuestionRow
  },

  data() {
    return {
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 3
      },
      error: {
        active: false,
        message: ""
      },
      profile_questions: []
    };
  },

  mounted() {
    this.fetchSystemProfileQuestions();
  },

  methods: {
    fetchSystemProfileQuestions() {
      this.$store
        .dispatch(POPULATE_PROFILE_QUESTIONS, false)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.profile_questions = this.systemProfileQuestions;
          this.fetchBasicProfileQuestions();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the profile questions list, please check back later or contact the helpdesk";
        });
    },
    fetchBasicProfileQuestions() {
      let payload = {
        projectId: this.projectInfo.id,
      }
      this.$store
        .dispatch(POPULATE_BASIC_PROFILE_QUESTIONS, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.mergeBasicProfileQuestions();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the profile questions list, please check back later or contact the helpdesk";
        });
    },
    mergeBasicProfileQuestions() {
      var i;
      for (i = 0; i < this.profile_questions.length; i++) {
        var result = this.basicProfileQuestions.find(obj => {
          return obj.system_profile_question_id === this.profile_questions[i].id;
        })

        if(result != undefined) {
          this.profile_questions[i].active = 1;
          this.profile_questions[i].required = result.required;
          this.profile_questions[i].display = result.display;
          this.profile_questions[i].basic_questions_entry = result.id;
        } else {
          this.profile_questions[i].active = 0;
          this.profile_questions[i].required = false;
          this.profile_questions[i].display = false;
          this.profile_questions[i].basic_questions_entry = null;
        }
      }
      this.pageLoader.componentsCompleted++;
    },
    activate_question(i) {
      this.profile_questions[i].required = false;
      this.profile_questions[i].display = false;

      let payload = {
        projectId: this.projectInfo.id,
        data: {
          system_profile_question_id: this.profile_questions[i].id,
          required: this.profile_questions[i].required,
          display: this.profile_questions[i].display
        }
      }
      this.$store
        .dispatch(CREATE_BASIC_PROFILE_QUESTION, payload)
        .then((res) => {
          this.profile_questions[i].basic_questions_entry = res.id;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues updating this basic profile question, please check back later or contact the helpdesk";
        })
        .finally(() => {
          document.getElementById('checkbox-active-' + this.profile_questions[i].id).disabled = false;
        });
    },
    deactivate_question(i) {
      this.profile_questions[i].required = false;
      this.profile_questions[i].display = false;

      let payload = {
        projectId: this.projectInfo.id,
        id: this.profile_questions[i].basic_questions_entry
      }
      this.$store
        .dispatch(DELETE_BASIC_PROFILE_QUESTION, payload)
        .then(() => {
          this.profile_questions[i].basic_questions_entry = null;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues updating this basic profile question, please check back later or contact the helpdesk";
        })
        .finally(() => {
          document.getElementById('checkbox-active-' + this.profile_questions[i].id).disabled = false;
        });
    },
    update_question(i) {
      let payload = {
        projectId: this.projectInfo.id,
        id: this.profile_questions[i].basic_questions_entry,
        data: {
          required: this.profile_questions[i].required,
          display: this.profile_questions[i].display
        }
      }
      this.$store
        .dispatch(UPDATE_BASIC_PROFILE_QUESTION, payload)
        .then(() => {})
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues updating this basic profile question, please check back later or contact the helpdesk";
        })
        .finally(() => {
          document.getElementById('checkbox-active-' + this.profile_questions[i].id).disabled = false;
          document.getElementById('checkbox-required-' + this.profile_questions[i].id).disabled = false;
          document.getElementById('checkbox-display-' + this.profile_questions[i].id).disabled = false;
        });
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "systemProfileQuestions",
      "basicProfileQuestions"
    ]),

    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>
