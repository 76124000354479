<template>
  <tr>
    <td class="text-center">
      <b-form-checkbox
        value="1"
        unchecked-value="0"
        size="lg"
        v-model="data.active"
        @change="toggleActive"
        :id="'checkbox-active-' + data.id"
      >
      </b-form-checkbox>
    </td>
    <td class="px-2 py-3 d-table-cell">
      <a
        href="javascript:void(0)"
        class="text-dark-65 font-weight-bolder text-hover-primary mb-1 font-size-sm"
      >
        {{ data.name }}
      </a>
    </td>
    <td class="text-center">
      <b-form-checkbox
        value="1"
        unchecked-value="0"
        size="lg"
        v-model="data.required"
        :id="'checkbox-required-' + data.id"
        :class="{ 'd-none' : data.active != '1'}"
        @change="toggleRequired"
      >
      </b-form-checkbox>                 
    </td>
    <td class="text-center">
      <b-form-checkbox
        value="1"
        unchecked-value="0"
        size="lg"
        v-model="data.display"
        :id="'checkbox-display-' + data.id"
        :class="{ 'd-none' : data.active != '1'}"
        @change="toggleDisplay"
      >
      </b-form-checkbox>
    </td>
  </tr>
</template>

<script>
export default {
  name: "BasicProfileQuestionRow",

  props: ["data", "index"],

  methods: {
    toggleActive() {
      document.getElementById('checkbox-active-' + this.data.id).disabled = true;

      let displayCheckbox = document.getElementById('checkbox-required-' + this.data.id);
      let requiredCheckbox = document.getElementById('checkbox-display-' + this.data.id);

      displayCheckbox.checked = false;
      requiredCheckbox.checked = false;

      if (this.data.active == 1) {
        displayCheckbox.parentElement.classList.remove("d-none");
        requiredCheckbox.parentElement.classList.remove("d-none");
        this.$emit("activate_question",this.index);
      } else {
        displayCheckbox.parentElement.classList.add("d-none");
        requiredCheckbox.parentElement.classList.add("d-none");
        this.$emit("deactivate_question",this.index);
      }
    },
    toggleRequired() {
      document.getElementById('checkbox-active-' + this.data.id).disabled = true;
      document.getElementById('checkbox-required-' + this.data.id).disabled = true;

      this.$emit('update_question',this.index);
    },
    toggleDisplay() {
      document.getElementById('checkbox-active-' + this.data.id).disabled = true;
      document.getElementById('checkbox-display-' + this.data.id).disabled = true;

      this.$emit('update_question',this.index);
    }
  },

  computed: {
    questionIsActive: function() {
      if (this.data.active == "1") {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>